<script src="https://www.google.com/recaptcha/api.js" async defer>
</script>

<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="4">
        <div class="text-h4 font-weight-bold">Kom in contact met mij</div>
        <div class="text-body-1">Vul dit formulier en ik ontvang een mail om kennis met je te maken en om te zien wat ik voor je kan doen</div>
        <v-card class="pa-4">
        <form>

          <div class="font-weight-bold">Email</div>
          <v-text-field v-model="emailDto.email"></v-text-field>
          <div class="font-weight-bold">Onderwerp</div>
          <v-text-field v-model="emailDto.subject"></v-text-field>

          <div class="font-weight-bold">Bericht</div>
          <v-text-field v-model="emailDto.body"></v-text-field>

          <v-btn @click="SendMail"> verstuur formulier</v-btn>
        </form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

</template>
<script>
import axios from "axios";

export default {
  name: "About",
  data(){
    return {
      emailDto: {
        subject: "",
        body: "",
        from: ""
      },
    }
  },
  methods: {
    SendMail() {
      if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.emailDto.email))
      {
        console.log("ee")
        alert("Email is ongeldig")
      }else if(this.emailDto.subject.length > 200){
        alert("Onderwerp is groter dan 200 characters")
      }else if(this.emailDto.body.length > 600){
        alert("Beschrijving is langer dan 600 characters")
      }
      else
        {
          axios.post("http://localhost:5180/api/Email/SendEmail", this.emailDto).then(response =>
          {
            if (response.data.data.status === 1){
              alert("Email kon niet verzonden worden controleer de velden")
            }
          })
        }
    }
  },

  created() {
    for (var i = 1; i < 99999; i++) {
      window.clearInterval(i);
    }
  }
}
</script>
